import store from "@/store";

// 获取存储本地数据
export function getLocalStor(name) {
  let localObj = JSON.parse(localStorage.getItem('"' + name + '"'));
  return localObj;
}
// 修改本地数据，可对比替换某一对象。无需全部填写
export function setLocalStor(name, data) {
  let localObj = JSON.parse(localStorage.getItem('"' + name + '"'));
  if (localObj && typeof localObj == "object") {
    for (const key in data) {
      localObj[key] = data[key];
    }
  } else {
    localObj = data;
  }
  localStorage.setItem(name, JSON.stringify(localObj));
}

//将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
// 防抖
export const debounce = (() => {
  let timer = null;
  return (callback, wait) => {
    clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };
})();
// 获取日期
export function getNowTime() {
  let date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth();
  let d =
    date.getDate() + 1 <= 9 ? "0" + (date.getDate() + 1) : date.getDate() + 1;
  return y + "-" + m + "-" + d;
}
// copy功能
export function linkUrl(title) {
  let url = title;
  let copyInput = document.createElement("input");
  document.body.appendChild(copyInput);
  copyInput.setAttribute("value", url);
  copyInput.select();
  document.execCommand("Copy");
  copyInput.remove();
}
// 登录成功后回到来源页面
export function goBackToSource(token) {
  let baseUrl = store.state.source
    ? store.state.source
    : process.env.VUE_APP_REDIRECT;
  let pushUrl = "";
  if (baseUrl.includes("?")) {
    pushUrl = baseUrl + "&token=" + encodeURIComponent(token);
  } else {
    pushUrl = baseUrl + "?token=" + encodeURIComponent(token);
  }
  window.location.replace(pushUrl);
}
